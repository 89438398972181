export function validateEmail(email) {
    const re = /^([a-z0-9._-]+)@([a-z.-]{3,})\.([a-z]{2,3})$/;
    const gmailTest = /@gmail\.([a-zA-Z]{2,3})$/;
    const yahooTest = /@yahoo\.([a-zA-Z]{2,3})$/;
    const hotmailTest = /@hotmail\.([a-zA-Z]{2,3})$/;

    const lower = String(email).toLowerCase()
    const test = !email || !re.test(lower)
    const notValidDomain = gmailTest.test(lower) || yahooTest.test(lower) || hotmailTest.test(lower)

    let error;
    if (test) {
        error = "Veuillez entrer une adresse email valide.";
    } else if (notValidDomain) {
        error = "Veuillez entrer votre adresse email professionnelle.";
    }
    return error;
}


export const validateImageFiles = (value, required) => {
    const types = ["image/jpeg", "image/jpg", "image/png", "image/webp"]
    console.log(value)
    if (required && value?.[0]?.length < 1) {
        return 'Une image est réquise.'
    }
    if (required) {
        if (types.includes(value?.[0]?.type)) {
            const MAX_FILE_SIZE = 100
            const fsMb = value?.[0].size / (1024 * 1024)

            if (fsMb > MAX_FILE_SIZE) {
                return "L'image ne doit pas dépasser les 10MB."
            }
        } else {
            if (value?.[0]?.type) {
                return 'Seuls les images peuvent être envoyées.'
            }
        }
    }
}


export const validateVideoFiles = (value) => {
    const types = ["video/mp4", "video/webm", "video/flv", "video/ogg"]
    if (value?.[0]?.length < 1) {
        return 'Une video est réquise.'
    }
    if (types.includes(value?.[0]?.type)) {
        const MAX_FILE_SIZE = 4000
        const fsMb = value?.[0].size / (1024 * 1024)
        if (fsMb > MAX_FILE_SIZE) {
            return "La video ne doit pas dépasser les 4GB."
        }
    } else {
        if (value?.[0]?.type) {
            return 'Seuls les vidéos peuvent être envoyées.'
        }
    }
}

export const validateAudioFiles = (value) => {
    const types = ["audio/mpeg", "audio/wav", "audio/mp4", "audio/ogg"]
    if (value?.[0]?.length < 1) {
        return 'Le fichier audio est réquis.'
    }
    if (types.includes(value?.[0]?.type)) {
        const MAX_FILE_SIZE = 200
        const fsMb = value?.[0].size / (1024 * 1024)

        if (fsMb > MAX_FILE_SIZE) {
            return "Le fichier ne doit pas dépasser les 200MB."
        }
    } else {
        if (value?.[0]?.type) {
            return 'Seuls les audios peuvent être envoyées.'
        }
    }
}