import {Flex} from '@chakra-ui/react';

import Logo from '@/assets/logo.svg';
import Glob from '@/assets/glob.svg';

const Authenticator = ({children}) => {
    return (
        <Flex direction={{base: "column", md: "row"}} alignItems={"stretch"} justifyContent={"center"} h={"100vh"} p={0}
              m={0}>
            <Flex direction={"column"} display={{base: "none", md: "flex"}} alignItems={"center"}
                  justifyContent={"center"} flex={{md: "1 1 auto"}}>
                <Glob
                    style={{
                        position: 'absolute',
                        top: '100%',
                        left: '0',
                        transform: 'translateY(-100%)',
                    }}
                />
                <Logo/>
            </Flex>
            <Flex direction={"column"} flex={"1 1 auto"} h={"100vh"} alignItems={"center"}
                  justifyContent={"space-evenly"} bg="#2A52BE" color={"#fff"}>
                {children}
            </Flex>
        </Flex>
    );
};

export default Authenticator;
