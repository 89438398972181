import {Button, Flex, FormControl, FormErrorMessage, Heading, Input, Stack, useToast} from '@chakra-ui/react';

import Authenticator from '@/components/Authenticator';
import {useState} from "react";
import {useForm} from "react-hook-form";
import {useAuth} from "@/contexts/AuthContext";
import {validateEmail} from "@/utils/validate-functions";
import {useRouter} from "next/router";

const Login = () => {
    const toast = useToast()
    const router = useRouter()
    const auth = useAuth();
    const [loading, setLoading] = useState(false)
    const {handleSubmit, register, formState: {errors, isValid, isDirty}} = useForm({mode: "all"});

    const onLogin = async (data) => {
        setLoading(true);
        const resp = await auth.login(data);
        if (resp?.data?.token) {
            setLoading(false);
            toast({
                title: 'Succès.',
                description: auth?.authSuccess || "Connexion réussie.",
                status: 'success',
                duration: 5000,
                isClosable: true,
            })
            await router.push("/dashboard")
        } else {
            setLoading(false);
            toast({
                title: 'Erreur d\'authentification.',
                description: resp?.data?.non_field_errors || auth?.authError?.non_field_errors,
                status: 'error',
                duration: 5000,
                isClosable: true,
            })
        }
    };

    return (
        <Authenticator>
            <Flex
                as={"form"}
                direction={"column"}
                w={{base: "320px", md: "360px"}}
                alignItems={"stretch"}
                justifyItems={"center"}
                flex={"0 0 auto"}
                errors={errors}
                {...register}
                onSubmit={handleSubmit((data) => onLogin(data))}
            >
                <Stack spacing={6}>
                    <Stack spacing={5} mb={2}>
                        <Heading as="h1" size="xl">
                            Bonjour
                        </Heading>
                        <Heading as="h2" size="md">
                            Bienvenue
                        </Heading>
                    </Stack>
                    <FormControl isRequired isInvalid={errors?.email && errors?.email.message}>
                        <Input
                            mb={2}
                            variant="rounded"
                            aria-label="Adresse Email"
                            id="email"
                            name={"email"}
                            border={"2px solid"}
                            borderColor={errors?.email ? "red.600" : "white"}
                            _hover={{opacity: 1, borderColor: errors?.email ? "red.600" : "white"}}
                            _focus={{
                                opacity: 1,
                                borderColor: errors?.email ? "red.600" : "white",
                                boxShadow: "#F9F9F9"
                            }}
                            autoComplete='email'
                            placeholder="Email"
                            {...register("email", {
                                required: 'Entrez votre adresse email.',
                                validate: validateEmail
                            })}
                            type="email"
                        />
                        <FormErrorMessage mt={2} ml={5} fontWeight={"medium"}>
                            {errors?.email && errors?.email.message}
                        </FormErrorMessage>
                    </FormControl>
                    <FormControl isRequired isInvalid={errors?.password && errors?.password.message}>
                        <Input
                            mb={2}
                            variant="rounded"
                            aria-label="Mot de Passe"
                            id="password"
                            name={"password"}
                            border={"2px solid"}
                            borderColor={errors?.password ? "red.600" : "white"}
                            _hover={{opacity: 1, borderColor: errors?.password ? "red.600" : "white"}}
                            _focus={{
                                opacity: 1,
                                borderColor: errors?.password ? "red.600" : "white",
                                boxShadow: "#F9F9F9"
                            }}
                            autoComplete='password'
                            placeholder="Mot de passe"
                            {...register("password", {
                                required: "Entrez votre mot de passe.",
                                minLength: {
                                    value: 3,
                                    message: "Le mot de passe doit contenir 8 caractères au minimum."
                                }
                            })}
                            type="password"
                        />
                        <FormErrorMessage mt={2} ml={5} fontWeight={"medium"}>
                            {errors?.password && errors?.password.message}
                        </FormErrorMessage>
                    </FormControl>
                </Stack>
                <Stack spacing={5}>
                    {/*<NextLink href='/forgot-password' passHref>*/}
                    {/*    <Link*/}
                    {/*        fontWeight={"medium"}*/}
                    {/*        _hover={{textDecoration: "none"}}*/}
                    {/*        _focus={{outline: "none",}}*/}
                    {/*        alignSelf={"flex-end"}*/}
                    {/*    >*/}
                    {/*        Mot de pass oublié?*/}
                    {/*    </Link>*/}
                    {/*</NextLink>*/}
                    <Button
                        variant="auth"
                        id="login"
                        py={7}
                        w={"full"}
                        isLoading={loading}
                        loadingText='Connexion...'
                        type="submit"
                        transform={"all 200ms ease-out"}
                        colorScheme="teal"
                        size="lg"
                        mt={12}
                        isFullWidth
                    >
                        Se connecter
                    </Button>
                    {/*<Text alignSelf={"center"} fontWeight={"regular"}>*/}
                    {/*    Vous n’avez pas de compte?{' '}*/}
                    {/*    <NextLink href='/login' passHref>*/}
                    {/*        <Link fontWeight={"medium"}>Inscrivez-vous</Link>*/}
                    {/*    </NextLink>.*/}
                    {/*</Text>*/}
                </Stack>
            </Flex>
        </Authenticator>
    );
};

export default Login;
